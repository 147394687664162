import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  footer: {
    paddingTop: '200px',
    textAlign: 'center',
  },
  footerLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

const Footer = ({ icp }) => {
  const classes = useStyles();

  const releaseYear = 2021;
  const currentYear = new Date().getFullYear();
  let durationString = `${releaseYear}`;
  if (currentYear > releaseYear) {
    durationString += `-${currentYear}`;
  }

  return (
    <div className={classes.footer}>
      <Typography variant="body2" color="text.secondary">
        Copyright ©&nbsp;
        {durationString}
        &nbsp;
        <a href="http://www.wangjinli.com/" className={classes.footerLink}>JINLI</a>
        .&nbsp;All Rights Reserved.&nbsp;|&nbsp;
        <a
          href="https://beian.miit.gov.cn/"
          target="_blank;"
          className={classes.footerLink}
        >
          {icp}
        </a>
      </Typography>
    </div>
  );
};

export default Footer;
