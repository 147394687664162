import React from 'react';
import { Grid } from '@material-ui/core';
import Entry from './Entry';

const EntryList = ({ entries }) => (
  <div className="entryList">
    <Grid container spacing={{ xs: 8, md: 12 }}>
      {entries.map((entry) => (
        <Entry
          title={entry.title}
          desc={entry.description}
          url={entry.url}
          icon={entry.icon}
          type={entry.type}
          key={entry.title}
        />
      ))}
    </Grid>
  </div>
);

export default EntryList;
