import { Container } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import EntryList from './EntryList';
import Footer from './Footer';
import Title from './Title';

const App = () => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [entries, setEntries] = useState([]);
  const [icp, setIcp] = useState('');

  useEffect(() => {
    fetch('/config.json')
      .then((response) => response.json())
      .then((config) => {
        setTitle(config.title);
        setSubtitle(config.subtitle);
        setEntries(config.entries);
        setIcp(config.icp);
      });
  }, []);

  return (
    <Container fixed>
      <Title title={title} subtitle={subtitle} />
      <EntryList entries={entries} />
      <Footer icp={icp} />
    </Container>
  );
};

export default App;
