import React from 'react';
import { Stack, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  siteTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
    marginBottom: '85px',
    fontFamily: 'Zen Antique',
    fontSize: '1.2rem',
  },
}));

const Title = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.siteTitle}>
      <Stack spacing={0}>
        <h1 style={{ marginBottom: 10 }}>{title}</h1>
        <Typography variant="body1" color="text.secondary" style={{ textAlign: 'center' }}>
          {subtitle}
        </Typography>
      </Stack>
    </div>
  );
};

export default Title;
