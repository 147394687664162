import React from 'react';
import {
  Card, CardContent, CardMedia, Chip, Grid, Typography,
} from '@material-ui/core';
import { LockOutlined, LockOpenOutlined } from '@material-ui/icons';
import { PropTypes } from 'prop-types';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  dsec: {
    '& a': {
      color: 'inherit',
    },
  },
}));

const Entry = (props) => {
  const {
    title, desc, url, type, icon,
  } = props;
  const classes = useStyles();

  return (
    <Grid item xs={6} sm={4} md={3}>
      <Card style={{ borderRadius: 0 }} elevation={0}>
        <a href={url}>
          <CardMedia
            component="img"
            image={`/EntryIcons/${icon}`}
            style={{ maxWidth: 100, borderRadius: 12 }}
          />
        </a>
        <CardContent style={{ paddingLeft: 0 }}>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className={classes.dsec}
            dangerouslySetInnerHTML={{ __html: desc }}
          />
          <br />
          {
            (type === 'private')
              ? <Chip size="small" icon={<LockOutlined />} label="Private" variant="outlined" />
              : <Chip size="small" icon={<LockOpenOutlined />} label="Public" variant="outlined" />
          }
        </CardContent>
      </Card>
    </Grid>
  );
};

Entry.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default Entry;
